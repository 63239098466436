/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        console.log('A Rebuild Group Website');
        console.log('https://rebuild.group');
        console.log('=======================');

        //HEADER
        var header = $(".fixed-header--full");
        $(window).scroll(function() {    
          var scroll = $(window).scrollTop();
          if (scroll >= 50) {
              header.removeClass('fixed-header--full').addClass("fixed-header--small");
          } else {
              header.removeClass("fixed-header--small").addClass('fixed-header--full');
          }
          var mq = window.matchMedia( "(min-width: 768px)" );
          if (mq.matches) {
            $(".banner-fade").css("opacity", 1 - $(window).scrollTop() / 250);
          } else {
            //no fade
          }
        });

        //FORM INPUTS
        $('.form-control').focusin(function(){
          $(this).parents('.form-group').addClass('has-value');
        });
        $('.form-control').focusout(function(){
          var text_val = $(this).val();
          if(text_val === "") {
            $(this).parents('.form-group').removeClass('has-value');
          } else {
            $(this).parents('.form-group').addClass('has-value');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(window).scroll(function(){
          $('.page__header--photo .fl-module-content').css('opacity', 1 - $(window).scrollTop() / 400);
          $('.page__header--photo .fl-row-content-wrap').css('opacity', 1 - $(window).scrollTop() / 225);
          //$('.page__header--photo .fl-row-content-wrap::before').css('opacity', 0 + $(window).scrollTop() / 200);
        });
      }
    },

    // News page
    'whats_new': {
      init: function() {
        // init Isotope
        // var $grid = $('.grid').isotope({
        //   itemSelector: '.item',
        //   percentPosition: true,
        //   gutter: 15,
        //   masonry: {
        //     columnWidth: '.grid-sizer'
        //   },
        //   sortBy: 'number',
        //   sortAscending: true
        // });
      },
      finalize: function() {
        // JavaScript to be fired after the init JS
      }
    },

    // Grants
    'grants_database': {
      init: function() {

        
        $header = $(".fixed-header");
        $banner = 272;
        $filterContainer = $(".filters__container");

        $(window).scroll(function() {    
          var scroll = $(window).scrollTop();
          //console.log(scroll);  //300

          if (scroll >= 300) {
              $filterContainer.addClass('fixed');
          } else {
              $filterContainer.removeClass('fixed');
          }
        
        });

        
        // init Isotope
        var $grid = $('.grid').isotope({
          itemSelector: '.item',
          percentPosition: true,
          gutter: 15,
          masonry: {
            columnWidth: '.grid-sizer'
          }
        });

        // NO RESULTS
        $grid.on( 'arrangeComplete', function( event, filteredItems ) {
          //console.log( filteredItems.length );
          if(filteredItems.length === 0){
            $(".no-results").show();
          }else{
            $(".no-results").hide();
          }
      });

        var $selects = $('#form-ui select');
        var $checkboxes = $('#form-ui input');

        $selects.add( $checkboxes ).change( function() {
          // map input values to an array
          var exclusives = [];
          var inclusives = [];
          // exclusive filters from selects
          $selects.each( function( i, elem ) {
            if ( elem.value ) {
              exclusives.push( elem.value );
            }
          });
          // inclusive filters from checkboxes
          $checkboxes.each( function( i, elem ) {
            // if checkbox, use value if checked
            if ( elem.checked ) {
              inclusives.push( elem.value );
            }
          });
        
          // combine exclusive and inclusive filters
        
          // first combine exclusives
          exclusives = exclusives.join('');
        
          var filterValue;

          if ( inclusives.length ) {
            // map inclusives with exclusives for
            filterValue = $.map( inclusives, function( value ) {
              return value + exclusives;
            });
            filterValue = filterValue.join(', ');
          } else {
            filterValue = exclusives;
          }
        
          //$output.text( filterValue );
          $grid.isotope({ filter: filterValue });
        });

        $('#resetFilters').on( 'click', function() {
          $selects.val('');
          $checkboxes.attr('checked', false);
          var filterValue;
          $grid.isotope({ filter: filterValue });
        });

      }
    },

    // Contact page
    'contact': {
      init: function() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions1

        var lat   = 42.329120;
        var long  = -83.050250;

        var mapOptions = {
          // How zoomed in you want the map to start at (always required)
          zoom: 16,

          mapTypeControl: false,
          scaleControl: true,
          scrollwheel: false,

          // The latitude and longitude to center the map (always required)
          center: new google.maps.LatLng(lat, long), // LOCATION

          // How you would like to style the map. 
          // This is where you would paste any style found on Snazzy Maps.
          styles: [
            {
                "featureType": "all",
                "stylers": [
                    {
                        "saturation": 0
                    },
                    {
                        "hue": "#e7ecf0"
                    }
                ]
            },
            {
                "featureType": "road",
                "stylers": [
                    {
                        "saturation": -70
                    }
                ]
            },
            {
                "featureType": "transit",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "hue": "#1900ff"
                  },
                  {
                      "color": "#a4ebdb"
                  }
              ]
          },
            {
                "featureType": "water",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "saturation": -60
                    }
                ]
            }
          ]
        };

        // Get the HTML DOM element that will contain your map 
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('googleMap');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        // Let's also add a marker while we're at it
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, long),
          map: map,
          title: 'Hudson Webber Foundation'
        });

        var contentString = 
          '<div id="content">'+
            '<h2 class="firstHeading">Hudson Webber Foundation</h2>'+
            '<div id="bodyContent">'+
                '<p>333 West Fort Street,<br />' +
                'Suite 1310<br />' +
                'Detroit, MI 48226</p>' +
                '<p><strong>(313)963-7777</strong></p>'+
                '<p><a href="https://goo.gl/maps/wVtFhJgYn5p" target="_blank">Get Directions</a></p>'+
            '</div>'+
          '</div>';

        var infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        marker.addListener('click', function() {
          infowindow.open(map, marker);
        });
      },
      finalize: function() {
        
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.